import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../styles/Navbar.css';
import Cookies from 'universal-cookie';
import { EventEmitter } from '../utils/EventEmitter';
import DropdownMenu from './DropdownMenu';

const Navbar = props => {
  const cookies = new Cookies();
  const cookieName = 'yhwhapparel_shoppingbag';

  const objectToArray = object => {
    return Object.keys(object).map(key => object[key]);
  };

  let bagNum = cookies.get(cookieName) ? objectToArray(cookies.get(cookieName)).length : 0;

  const [cartSize, setCartSize] = useState(bagNum);

  const updateCart = event => {
    bagNum = cookies.get(cookieName) ? objectToArray(cookies.get(cookieName)).length : 0;
    setCartSize(bagNum);
  };

  EventEmitter.subscribe('updateCart', event => updateCart(event));

  /* Dropdown menus */
  const [isAboutDropdownVisible, setAboutDropdownVisible] = useState(false);
  const [isDesignsDropdownVisible, setDesignsDropdownVisible] = useState(false);


  const handleMouseEnterAbout = () => {
    setAboutDropdownVisible(true);
  };

  const handleMouseLeaveAbout = () => {
    setAboutDropdownVisible(false);
  };

  const handleMouseEnterDesigns= () => {
    setDesignsDropdownVisible(true);
  };

  const handleMouseLeaveDesigns = () => {
    setDesignsDropdownVisible(false);
  };

  return (
    <div className='navbar-container'>
      <div className='logo'>
        <Link to='/'>
          <img src={require('../images/logo.png')} alt='Logo' id='logo-image' />
        </Link>
      </div>
      <div className='navlinks'>
        <ul>
          <li>
            <Link to='/about'>
              <div className='navlink' onMouseEnter={handleMouseEnterAbout} onMouseLeave={handleMouseLeaveAbout}>
                <div className='navlinkTitle'>ABOUT YHWH APPAREL</div>
                {isAboutDropdownVisible && <DropdownMenu items={[ { page: '/about', label: 'Our Vision', ref: 'our_vision'}, { page: '/about', label: 'Our Mission', ref: 'our_mission'}]}/>}
              </div>
            </Link>
          </li>
          <li>
            <Link to='/staff'>
              <div className='navlinkTitle'>OUR TEAM</div>
            </Link>
          </li>
          <li>
            <Link to='/designs'>
            <div className='navlink' onMouseEnter={handleMouseEnterDesigns} onMouseLeave={handleMouseLeaveDesigns}>
                <div className='navlinkTitle'>DESIGNS</div>
                {isDesignsDropdownVisible && <DropdownMenu items={[ { page: '/designs', label: 'Our Process', ref: 'our_process'}, { page: '/designs', label: 'Our Products', ref: 'our_products'}]}/>}
              </div>
            </Link>
          </li>
          <li>
            <Link to='/shop'>
              <div className='navlinkTitle'>SHOP</div>
            </Link>
            {/* <a href='https://yhwhapparel.storenvy.com/products'>
              <div className='navlink'>SHOP</div>
            </a> */}
          </li>
          <li>
            <Link to='/partnerships'>
              <div className='navlinkTitle'>PARTNERSHIPS</div>
            </Link>
          </li>
        </ul>
      </div>
      <div className='bag'>
        <Link to='/checkout'>
          <div className='bag-image'>
            <img src={require('../images/shop/shopping_bag.png')} />
          </div>
          <div className={'bag-num-background ' + (cartSize == 0 ? 'empty' : 'items')}>
            <div className='bag-num'>{cartSize == 0 ? '' : cartSize}</div>
          </div>
        </Link>
          </div>
    </div>
  );
};

export default Navbar;
