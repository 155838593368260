import React from 'react';

const Staff = [
  {
    team: 'Executive',
    members: [
      {
        name: 'Kristin Joe',
        role: 'CEO',
        description: (
          <div>
            <h3>
            HEYLO!! I’m Kristin, a sophomore studying data science and cognitive science ~ A little about me: 
              I’m an INFP on Myers-Briggs, and you can probably find me MLKing, thrifting, working out, or 
              exploring obscure locations LOL. This is my fourth semester in YHWH Apparel (golly :0), and it 
              has been so exciting to see how the design process for every semester’s product has looked so 
              different but equally as intentional. I’m sohhhh excited for what God has in store for YHWH this 
              semester :->
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/kristin.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/kristin.jpg')
      },
      {
        name: 'Elisabeth Shin',
        role: 'Vice President',
        description: (
          <div>
            <h3>
            HelllooOOO!! I’m Elisabeth, a 2nd-year studying cognitive science, and this is my third semester 
            (holy moly) serving with YHWH Apparel!! I love chattin it up with my friends over a cup of coffee, 
            reading a good book, jamming w/ my housemates, and playing brawl stars(💀)! This team is genuinely 
            so fire and Ms. CEO Kristin Joe Mama has been such a blessing❤️‍🔥. I’m so hyped to show y’all what we’ve 
            been learning about our amazing God and His unfailing love for us!! :))
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/elisabeth.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/elisabeth.jpg')
      }
    ]
  },
  {
    team: 'Marketing',
    members: [
      {
        name: 'Enoch Mun',
        role: 'Marketing Associate',
        description: (
          <div>
            <h3>
            Hi everyone my name is Enoch I am a second year intending to study statistics. This is my 
            3rd semester on the marketing team with YHWH and I am super excited to be back. If you ever 
            see me wandering around campus just know I enjoy almost everything so feel free to come up 
            and talk to me about even the most random things and I promise I will be welcoming!
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/enoch.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/enoch.jpg')
      }, 
      {
        name: 'Wendy Liu',
        role: 'Marketing Associate',
        description: (
          <div>
            <h3>
            Hallooo my name is Wendy Liu and I’m a second year studying cognitive science and data science. 
            This is my second semester at YHWH and I’m excited to create with these wonderful beautiful people 
            :)
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/wendy.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/wendy.jpg')
      }
    ]
  },
  {
    team: 'Product Design',
    members: [
      {
        name: 'Luna Nam',
        role: 'Product Designer',
        description: (
          <div>
            <h3>
            {`Hiiii~ I’m Luna a sophomore studying Applied Mathematics! This is my first semester with YHWH apparel 
            and I am SO SO SO excited to spread God’s Goodness with the team <3`}
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/luna.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/luna.jpg')
      },
      {
        name: 'Priscilla Paik',
        role: 'Product Designer',
        description: (
          <div>
            <h3>
            Hiii I’m Priscilla and a first year studying Operations Research Management Science (ORMS). 
            So excited to be a part of YHWH this semester!
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/priscilla.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/priscilla.jpg')
      }
    ]
  },
  {
    team: 'Business Operations',
    members: [
      {
        name: 'Johnavon Kim',
        role: 'Business Operations Associate',
        description: (
          <div>
            <h3>
            Hiiii~ I’m Johnavon a sophomore studying Data Science and Econ! This is my first 
            semester with YHWH apparel and I am excited to spread God’s word and be a part of 
            such a wonderful and talented team!
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/johnavon.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/johnavon.jpg')
      },
      {
        name: 'Paul Choi',
        role: 'Business Operations Associate',
        description: (
          <div>
            <h3>
            Hello I’m Paul, a sophomore studying mechanical engineering. As is my first semester 
            in YHWH, I’m looking forward to working with everyone and serving the Lord together!
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/paul.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/paul.jpg')
      },
      {
        name: 'Jane Januar',
        role: 'Business Operations Associate',
        description: (
          <div>
            <h3>
            I’m Jane, a sophomore studying Industrial Engineering and Operations Research (long name lol). 
            This is my first semester at YHWH and I’m super excited to see what God has in store for this 
            team and for this semester’s product! 
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/jane.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/jane.jpg')
      }
    ]
  }, 
  {
    team: 'Web Development',
    members: [
      {
        name: 'Kai Wen',
        role: 'Web Developer',
        description: (
          <div>
            <h3>
            Hi! I’m Kai and I’m a third-year studying EECS. This is my first semester in YHWH and I’m excited to 
            see how I can serve and further God’s word! 
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/kai.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/kai.jpg')
      },
      {
        name: 'Albert Su',
        role: 'Web Developer',
        description: (
          <div>
            <h3>
            Hello, I’m Albert and I’m a sophomore studying mathematics and computer science. This is my 
            first semester with YHWH and I’m excited to join the team!
            </h3>
          </div>
        ),
        imgSrcDesktop: require('../images/staff/desktop-staff/albert.jpg'),
        imgSrcMobile: require('../images/staff/mobile-staff/albert.jpg')
      }
    ]
  }
];

export default Staff;
