import React, { useRef, useEffect } from "react";
import './../styles/dropdown.css'
import { Link, useLocation, useHistory } from 'react-router-dom';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

  const DropdownMenu = ({items}) => {
    const history = useHistory();
    const location = useLocation();

    /*
      useEffect(() => {
        var targetDiv = document.getElementById(target);
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, [history]);
    */

    function scrollToDiv(target, page, history, location) {
      if(location.pathname != page){
        history.push(page); 
        
        setTimeout(() => {
          var targetDiv = document.getElementById(target);
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 400); // scuffed
      } else {
        var targetDiv = document.getElementById(target);
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }

    return (
      <div className="dropdown-menu">
        <ul class="dd_menu">
          {items.map(item => (
            <li onClick={() => scrollToDiv(item.ref, item.page, history, location)}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  };



  export default DropdownMenu;